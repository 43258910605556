<template>
  <h-card p0>
    <b-row class="mx-0" v-if="profil && profil.user">
      <b-col
        md="4"
        class="profil d-flex flex-column justify-content-center py-5 px-3"
      >
        <div
          class="img-container bg-white d-flex align-items-center justify-content-center"
        >
          <b-img :src="logoSrc" alt="logo"></b-img>
        </div>
        <div class="d-flex flex-column justify-content-center">
          <h4 class="text-center mt-4 text-white mb-b">
            {{ profil.user.visibleBrands[0].name }}
          </h4>
          <div class="d-flex justify-content-center">
            <b-badge pill variant="light" class="py-2 px-3">{{
              profil.user.roles[0].name
            }}</b-badge>
          </div>
        </div>
      </b-col>
      <b-col class="py-3 px-4">
        <b-row>
          <b-col cols="12" class="d-lg-flex justify-content-end">
            <h-button
              @click="editSaveAction()"
              :disabled="!changeEmailValue"
              size="sm"
              :green="editMode"
              :blue="!editMode"
              class="d-flex align-items-center"
            >
              <b-icon
                :icon="editMode ? 'arrow-down-circle-fill' : 'pencil-fill'"
                class="w-15px mr-2"
              >
              </b-icon>
              {{
                editMode ? $t('profile.saveMyInfos') : $t('profile.editMyInfos')
              }}
            </h-button>
          </b-col>
          <b-col lg="6">
            <h5 class="font-weight-bold">{{ $t('profile.lastname') }}</h5>
            <h-input
              v-if="editMode"
              v-model.trim="user.LastName"
              :value-prop="profil.user.lastname"
            ></h-input>
            <span v-else>{{ existInData(profil.user.lastname) }}</span>
          </b-col>
          <b-col lg="6">
            <h5 class="font-weight-bold">{{ $t('profile.firstname') }}</h5>
            <h-input
              v-if="editMode"
              v-model.trim="user.FirstName"
              :value-prop="profil.user.firstname"
            ></h-input>
            <span v-else>{{ existInData(profil.user.firstname) }}</span>
          </b-col>
          <b-col lg="6">
            <h5 class="font-weight-bold">{{ $t('profile.email') }}</h5>
            <h-input
              v-if="editMode"
              v-model.trim="user.Email"
              :errorState="($v.user.Email.$error && $v.$dirty) || emailTaken"
              :errorFeedback="
                emailTaken
                  ? $t('profile.errorEmailAlreadyUsed')
                  : $t('profile.errorEmailBadFormat')
              "
              :value-prop="profil.user.email"
              @input="changeEmailValue = true"
            ></h-input>
            <span v-else>{{ existInData(profil.user.email) }}</span>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col lg="6">
            <h5 class="font-weight-bold">{{ $t('profile.password') }}</h5>
            <span>*********</span>
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-md-start justify-content-lg-end"
          >
            <h-button
              @click="showModal"
              size="sm"
              purple
              class="d-flex align-items-center mt-3"
            >
              <b-icon icon="shield-lock-fill" class="w-15px mr-2"></b-icon>
              {{ $t('profile.editPassword') }}
            </h-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <ModalChangePassword
      :showPasswordModal="showPasswordModal"
      @close="showPasswordModal = false"
    />
  </h-card>
</template>

<script>
import { mapState } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import ModalChangePassword from '@/components/modals/ModalChangePassword'
import userSerice from '@/services/userService'
export default {
  components: {
    ModalChangePassword,
  },
  computed: {
    logoSrc() {
      return this.$store.getters['user/logoSrc']
    },
    ...mapState({
      profil: (state) => state.user,
    }),
  },
  data() {
    return {
      editMode: false,
      emailTaken: false,
      changeEmailValue: true,
      showPasswordModal: false,
      user: {
        FirstName: '',
        LastName: '',
        Email: '',
      },
    }
  },
  validations: {
    user: {
      Email: { required, email },
    },
  },
  methods: {
    editSaveAction() {
      this.emailTaken = false
      if (this.editMode) {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          const User = this.user
          this.editUser(this.profil.user.id, User)
        }
      } else {
        this.editMode = !this.editMode
      }
    },
    async editUser(id, user) {
      try {
        await userSerice.editUser(id, user)
        this.$store.dispatch('user/_updateUser', user)
        this.editMode = !this.editMode
        this.emailTaken = false
        this.$toast.success(this.$t('profile.editSuccessMessage'))
      } catch (err) {
        if (err.response.status === 409) {
          this.changeEmailValue = false
          this.emailTaken = true
          this.$toast.error(this.$t('profile.editErrorEmail'))
        } else this.$toast.error(this.$t('errors.generic'))
      }
    },
    existInData(val) {
      if (val) return val
      else return '-'
    },
    showModal() {
      this.showPasswordModal = true
    },
  },
}
</script>

<style lang="scss" scoped>
.profil {
  background: $primary;
  border-radius: 0.25rem;
  background: linear-gradient(49deg, $primary 0%, $primary-fade 100%);
  color: white;
  .img-container {
    margin: 0 auto;
    width: 170px;
    height: 170px;
    border-radius: 999px;
    box-shadow: 0 5px 10px -6px #53545f;
    img {
      width: 80%;
    }
  }
}
</style>
