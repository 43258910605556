<template>
  <b-modal
    v-model="showPasswordModal"
    hide-header
    hide-footer
    centered
    no-close-on-backdrop
    v-if="showPasswordModal"
  >
    <transition name="slide-fade" mode="out-in">
      <div v-if="success" key="2" class="px-2">
        <div class="d-block text-center">
          <h4>
            {{ $t('profile.passwordSuccessTitle') }}
          </h4>
        </div>
        <div class="mt-5 w-50 mx-auto">
          <b-img
            :src="require('@/assets/svg/illu_password_changed.svg')"
            fluid
            alt="password changed"
          ></b-img>
          <!-- <b-img
            :src="require('@/assets/svg/illu-email-password-sent.svg')"
            fluid
            alt="email sent"
          ></b-img> -->
        </div>
        <div class="my-5">
          <p class="lead large text-center">
            {{ $t('profile.passwordSuccessMessage') }}
          </p>
          <!-- <p class="lead large text-center">
            Un email vous a été envoyé. Merci de cliquer sur le lien de
            confirmation pour finaliser le changement de votre mot de passe.
          </p> -->
        </div>
        <div class="d-flex mt-3">
          <h-button
            @click="closeAfterMail"
            outline
            grey
            class="mx-auto px-3 mr-3"
            >{{ $t('actions.close') }}
          </h-button>
        </div>
      </div>
      <div v-else key="1" class="px-2">
        <div class="d-block text-center">
          <h4>
            {{ $t('profile.passwordEditTitle') }}
          </h4>
        </div>
        <div class="my-5">
          <h-input
            :label="$t('profile.currentPassword')"
            v-model.trim="$v.oldPassword.$model"
            :errorState="
              ($v.oldPassword.$error && $v.$dirty) || errorOldPassword
            "
            :errorFeedback="
              !$v.oldPassword.required
                ? $t('profile.errorCurrentPasswordRequired')
                : $t('profile.errorCurrentPasswordInvalid')
            "
            type="password"
          ></h-input>
          <h-input
            :label="$t('profile.newPassword')"
            type="password"
            :errorState="$v.newPassword.$error && $v.$dirty"
            v-model.trim="$v.newPassword.$model"
            :errorFeedback="passwordFeedBack"
            class="mt-3"
          ></h-input>
          <h-input
            :label="$t('profile.confirmPassword')"
            type="password"
            v-model.trim="$v.passwordConfirmation.$model"
            :errorState="$v.passwordConfirmation.$error && $v.$dirty"
            :errorFeedback="
              !$v.passwordConfirmation.sameAsPassword
                ? $t('profile.errorSamePassword')
                : ''
            "
            class="mt-3"
          ></h-input>
        </div>
        <div class="d-flex mt-3">
          <h-button
            @click="$emit('close')"
            outline
            :errorState="!formValid"
            red
            class="ml-auto px-3 mr-3"
          >
            {{ $t('actions.cancel') }}
          </h-button>
          <h-button class="px-3" green @click="editPassword">
            {{ $t('actions.save') }}
          </h-button>
        </div>
      </div>
    </transition>
  </b-modal>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'
import userSerice from '@/services/userService'
export default {
  props: {
    showPasswordModal: Boolean,
  },
  data() {
    return {
      errorOldPassword: false,
      formValid: true,
      success: false,
      oldPassword: '',
      newPassword: '',
      passwordConfirmation: '',
    }
  },
  validations: {
    oldPassword: { required },
    newPassword: {
      required,
      minLength: minLength(8),
      valid: function(value) {
        const containsUppercase = /[A-Z]/.test(value)
        const containsLowercase = /[a-z]/.test(value)
        const containsNumber = /[0-9]/.test(value)
        return containsUppercase && containsLowercase && containsNumber
      },
    },
    passwordConfirmation: { required, sameAsPassword: sameAs('newPassword') },
  },
  computed: {
    passwordFeedBack() {
      if (
        !this.$v.newPassword.minLength ||
        this.$v.newPassword.$model.length === 0
      ) {
        return `${this.$t('profile.errorPasswordSize1')} ${
          this.$v.newPassword.$params.minLength.min
        } ${this.$t('profile.errorPasswordSize2')}`
      } else if (!this.$v.newPassword.valid) {
        return this.$t('profile.errorPasswordRules')
      } else return ''
    },
    ...mapState({
      profil: (state) => state.user,
    }),
  },
  methods: {
    editPassword() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.formValid = false
        return false
      } else {
        const params = {
          OldPassword: this.oldPassword,
          NewPassword: this.newPassword,
        }
        this.changePassword(this.profil.user.id, params)
      }
    },
    async changePassword(id, params) {
      try {
        await userSerice.changePassword(id, params)
        this.errorOldPassword = false
        this.success = true
      } catch (err) {
        if (err.response.status === 404) {
          this.errorOldPassword = true
        } else {
          this.$toast.error(this.$t('errors.generic'))
        }
      }
    },
    closeAfterMail() {
      this.success = false
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped></style>
